<template>
  <div>
    <v-row>
      <v-col cols="12">
        <p class="text-2xl">
          Serviços
        </p>
        <v-card flat>
          <v-card-text class="pa-3">
            <v-row>
              <v-col
                cols="12"
                md="3"
                sm="6"
              >
                <v-text-field
                  v-model="campaignId"
                  :append-icon="icons.mdiMagnify"
                  label="ID"
                  hide-details
                  outlined
                  dense
                  @click:append="fetchReportServices"
                  @keypress.enter="fetchReportServices"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="6"
              >
                <v-text-field
                  v-model="campaignName"
                  :append-icon="icons.mdiMagnify"
                  label="Nome"
                  hide-details
                  outlined
                  dense
                  @click:append="fetchReportServices"
                  @keypress.enter="fetchReportServices"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
                sm="6"
              >
                <v-autocomplete
                  v-model="serviceId"
                  :items="services"
                  clearable
                  placeholder="Todos"
                  label="Serviço"
                  outlined
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  @change="fetchReportServices"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
                sm="6"
              >
                <date
                  v-model="sendDateStart"
                  :type="'date'"
                  outlined
                  hide-details
                  label="Data Envio (Início)"
                  readonly
                  dense
                  @input="fetchReportServices"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
                sm="6"
              >
                <date
                  v-model="sendDateEnd"
                  :type="'date'"
                  outlined
                  dense
                  hide-details
                  label="Data Envio (Fim)"
                  readonly
                  @input="fetchReportServices"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
                sm="6"
              >
                <v-autocomplete
                  v-model="internalStatus"
                  :items="status"
                  clearable
                  dense
                  placeholder="Todos"
                  label="Status"
                  outlined
                  hide-details
                  item-text="label"
                  item-value="value"
                  @change="fetchReportServices"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
                sm="6"
              >
                <v-autocomplete
                  v-model="isPaused"
                  :items="isPausedStatus"
                  clearable
                  dense
                  placeholder="Todos"
                  label="Pausada?"
                  outlined
                  hide-details
                  item-text="label"
                  item-value="value"
                  @change="fetchReportServices"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-3">
            <v-btn
              color="primary"
              class="pl-4"
              @click="fetchReportServices"
            >
              Pesquisar
              <v-icon
                right
                dark
              >
                {{ icons.mdiMagnify }}
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          flat
          class="mt-5"
        >
          <v-data-table
            :headers="headers"
            :items="rows"
            :items-per-page="5"
            dense
            item-key="id"
            class="table-rounded"
            disable-sort
          >
            <template #[`item.action`]="{ item }">
              <v-btn
                color="info"
                dark
                text
                title="Visualizar"
                x-small
                @click="openDialog(item)"
              >
                <v-icon>{{ icons.mdiEye }}</v-icon>
              </v-btn>
              <v-btn
                color="success"
                dark
                text
                title="Editar"
                x-small
                :disabled="item.internalStatus !== 1"
                @click="openEditModal(item.id)"
              >
                <v-icon>{{ icons.mdiFileEdit }}</v-icon>
              </v-btn>
            </template>
            <template #[`item.name`]="{ item }">
              <div class="d-flex flex-column">
                <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
                <small>ID: <b>{{ item.id }}</b></small>
              </div>
            </template>
            <template #[`item.internalStatus`]="{ item }">
              <v-chip
                class="my-2"
                :color="resolveStatus(item.internalStatus).color"
                small
                label
              >
                <span class="white--text font-weight-semibold">
                  {{ resolveStatus(item.internalStatus).label }}
                </span>
              </v-chip>
            </template>
            <template #[`item.serviceName`]="{ item }">
              {{ item.serviceName }}
            </template>
            <template #[`item.sendDate`]="{ item }">
              <div class="d-flex flex-column my-1">
                <span>
                  <v-icon
                    class="me-1"
                    size="18"
                  >
                    {{ icons.mdiCalendar }}
                  </v-icon>
                  {{ $day(item.sendDate).format('DD/MM/YYYY') }}
                </span>
                <span>
                  <v-icon
                    class="me-1"
                    size="18"
                  >
                    {{ icons.mdiClock }}
                  </v-icon>
                  {{ $day(item.sendDate).format('HH:mm') }}
                </span>
              </div>
            </template>
            <template #[`item.isPaused`]="{ item }">
              {{ resolveSituation(item.isPaused) }}
            </template>
            <template #[`item.quantity`]="{ item }">
              {{ $money(item.quantity, 0) }}
            </template>
            <template #[`item.validQuantity`]="{ item }">
              {{ $money(item.validQuantity, 0) }}
            </template>
            <template #[`item.invalidQuantity`]="{ item }">
              {{ $money(item.invalidQuantity, 0) }}
            </template>
            <template #[`item.value`]="{ item }">
              {{ $money(item.value, 3) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="campaignDialog"
      :width="1500"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Campanha (#{{ campaignSelected.id }})
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
              cols="6"
              md="2"
              sm="6"
            >
              <v-text-field
                v-model="campaignSelected.id"
                outlined
                hide-details
                label="Código"
                readonly
                dense
              />
            </v-col>
            <v-col
              cols="6"
              md="4"
              sm="6"
            >
              <v-text-field
                v-model="campaignSelected.name"
                outlined
                hide-details
                label="Campanha"
                readonly
                dense
              />
            </v-col>
            <v-col
              cols="6"
              md="2"
              sm="6"
            >
              <v-select
                v-model="campaignSelected.serviceId"
                :items="services"
                label="Serviço"
                outlined
                hide-details
                item-text="name"
                item-value="id"
                readonly
                dense
              />
            </v-col>
            <v-col
              cols="6"
              md="2"
              sm="6"
            >
              <v-select
                v-model="campaignSelected.internalStatus"
                :items="status"
                label="Status"
                outlined
                hide-details
                item-text="label"
                item-value="value"
                readonly
                dense
              />
            </v-col>
            <v-col
              cols="6"
              md="2"
              sm="6"
            >
              <v-text-field
                :value="$day(campaignSelected.sendDate).format('DD/MM/YYYY HH:mm:ss')"
                outlined
                hide-details
                label="Data Envio"
                readonly
                dense
              />
            </v-col>
            <v-col
              cols="6"
              md="4"
              sm="6"
            >
              <v-text-field
                :value="campaignSelected.userName"
                outlined
                hide-details
                label="Cliente"
                readonly
                dense
              />
            </v-col>
            <v-col
              cols="6"
              md="2"
              sm="6"
            >
              <v-text-field
                :value="$money(campaignSelected.value, 3)"
                outlined
                hide-details
                label="Preço (R$)"
                readonly
                dense
              />
            </v-col>
            <v-col
              cols="6"
              md="2"
              sm="6"
            >
              <v-text-field
                :value="$money(campaignSelected.quantity, 0)"
                outlined
                hide-details
                label="Qtd Números"
                readonly
                dense
              />
            </v-col>
            <v-col
              cols="6"
              md="2"
              sm="6"
            >
              <v-text-field
                :value="$money(campaignSelected.validQuantity, 0)"
                outlined
                hide-details
                label="Qtd com atividade"
                readonly
                dense
              />
            </v-col>
            <v-col
              cols="6"
              md="2"
              sm="6"
            >
              <v-text-field
                :value="$money(campaignSelected.invalidQuantity, 0)"
                outlined
                hide-details
                label="Qtd sem atividade"
                readonly
                dense
              />
            </v-col>
            <v-col
              v-if="campaignSelected.webhookUrl"
              cols="6"
              md="4"
              sm="6"
            >
              <v-text-field
                :value="campaignSelected.webhookUrl"
                outlined
                hide-details
                label="Webhook URL"
                readonly
                dense
              />
            </v-col>
            <v-col
              v-if="[serviceType.SMSShortcode, serviceType.SMSToken, serviceType.SMSFlash, serviceType.SMSMassive, serviceType.SMSBet].includes(campaignSelected.serviceId)"
              cols="6"
              md="2"
              sm="6"
            >
              <v-select
                v-model="campaignServiceSelected.hasInteraction"
                :items="hasInteractionStatus"
                label="Tem Interação?"
                outlined
                hide-details
                item-text="label"
                item-value="value"
                readonly
                dense
              />
            </v-col>
          </v-row>
          <v-row v-if="[serviceType.Voice30s, serviceType.Voice60s].includes(campaignSelected.serviceId)">
            <v-col
              cols="6"
              md="2"
              sm="6"
            >
              <v-select
                v-model="campaignServiceSelected.typeVoice"
                :items="voiceTypes"
                label="Tipo Voz"
                outlined
                hide-details
                item-text="text"
                item-value="value"
                readonly
                dense
              />
            </v-col>
            <v-col
              cols="6"
              md="2"
              sm="6"
            >
              <v-text-field
                :value="campaignServiceSelected.dialSpeed"
                outlined
                hide-details
                label="Velocidade Discagem"
                readonly
                dense
              />
            </v-col>
            <v-col
              cols="6"
              md="2"
              sm="6"
            >
              <v-text-field
                :value="campaignServiceSelected.attempt"
                outlined
                hide-details
                label="Tentativas"
                readonly
                dense
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            :disabled="campaignSelected.internalStatus != status[0].value"
            color="error"
            outlined
            @click="cancelSelectedCampaign"
          >
            Cancelar
          </v-btn>
          <v-spacer />
          <v-btn
            color="secondary"
            outlined
            @click="campaignDialog = !campaignDialog"
          >
            Fechar
          </v-btn>
          <v-btn
            :disabled="campaignSelected.internalStatus != status[2].value"
            color="primary"
            class="px-4"
            filled
            @click="downloadReport"
          >
            <v-icon
              left
              dark
            >
              {{ icons.mdiFileDownload }}
            </v-icon>
            Download Relatório
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <validation-observer
      ref="formCampaign"
      autocomplete="off"
      tag="form"
      @keyup.enter="fetchAction"
    >
      <dialog-form
        :status="formDialogCampaignStatus"
        :is-update="!!formCampaign.id"
        :width="600"
        title="Editar Campanha"
        hide-remove-btn
        @action="fetchActionCampaign"
        @close="formDialogCampaignStatus = !formDialogCampaignStatus"
      >
        <validation-provider
          v-slot="{ errors }"
          name="Nome"
          rules="required|max:100"
          vid="formCampaign.name"
        >
          <v-text-field
            v-model="formCampaign.name"
            :error-messages="errors"
            label="Nome"
            type="text"
            outlined
            dense
          />
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          label="Date e hora"
          rules="required"
          vid="formCampaign.sendDate"
        >
          <v-datetime-picker
            v-model="formCampaign.sendDate"
            :text-field-props="{
              errorMessages: errors,
              outlined: true,
              dense: true,
            }"
            :value="campaignSelected.sendDate"
            hide-details
            label="Data e hora de envio"
            outlined
            dense
            date-format="MM/dd/yyyy"
          >
            <template slot="dateIcon">
              <v-icon>{{ icons.mdiCalendarBlank }}</v-icon>
            </template>
            <template slot="timeIcon">
              <v-icon>{{ icons.mdiClockTimeElevenOutline }}</v-icon>
            </template>
            <template
              slot="actions"
              slot-scope="{ parent }"
            >
              <div class="pt-3 pt-0">
                <v-btn
                  color="error lighten-1"
                  class="me-3"
                  @click.native="parent.clearHandler"
                >
                  Limpar
                </v-btn>
                <v-btn
                  color="success darken-1"
                  @click="parent.okHandler"
                >
                  Ok
                </v-btn>
              </div>
            </template>
          </v-datetime-picker>
        </validation-provider>
      </dialog-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DatetimePicker from 'vuetify-datetime-picker'
import Vue from 'vue'
import {
  mdiEye,
  mdiMagnify,
  mdiMessageTextOutline,
  mdiPhonePlusOutline,
  mdiCheckAll,
  mdiCalendar,
  mdiClock,
  mdiFileEdit,
  mdiFileDownload,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import {
  campaignStatus,
  hasInteractionStatus,
  isPausedStatus,
  serviceType,
  smsTypes,
  voiceTypes,
} from '@/utils/enum'

Vue.use(DatetimePicker)
export default {
  setup() {
    const campaignDialog = ref(false)
    const campaignServiceSelected = ref({})
    const campaignSelected = ref({})
    const services = ref([])

    const campaignId = ref(null)
    const campaignName = ref(null)
    const internalStatus = ref(null)
    const isPaused = ref(null)
    const sendDateStart = ref(null)
    const sendDateEnd = ref(null)
    const serviceId = ref(null)

    const resolveStatus = value => {
      const status = campaignStatus.find(item => item.value === value)

      if (status) {
        return status
      }

      return { color: 'error', label: 'Não definido' }
    }

    const resolveSituation = value => {
      const isPaused = isPausedStatus.find(item => item.value === value)

      if (isPaused) {
        return isPaused.label
      }

      return null
    }

    return {
      campaignDialog,
      campaignServiceSelected,
      campaignSelected,
      services,
      status: campaignStatus,
      hasInteractionStatus,
      isPausedStatus,
      campaignId,
      campaignName,
      internalStatus,
      isPaused,
      sendDateStart,
      sendDateEnd,
      serviceId,
      resolveStatus,
      resolveSituation,
      serviceType,
      voiceTypes,
      icons: {
        mdiEye,
        mdiMagnify,
        mdiMessageTextOutline,
        mdiPhonePlusOutline,
        mdiCheckAll,
        mdiCalendar,
        mdiClock,
        mdiFileDownload,
        mdiFileEdit,
      },
      headers: [
        { text: 'Ações', value: 'action', align: 'center' },
        { text: 'Campanha', value: 'name' },
        { text: 'Status', value: 'internalStatus' },
        { text: 'Tipo', value: 'serviceName' },
        { text: 'Cliente', value: 'userName' },
        { text: 'Data Envio', value: 'sendDate' },
        { text: 'Preço (R$)', value: 'value', align: 'right' },
        { text: 'Quantidade', value: 'quantity', align: 'right' },
        { text: 'Ativos', value: 'validQuantity', align: 'right' },
        { text: 'Sem Atividade', value: 'invalidQuantity', align: 'right' },
      ],
      smsTypes,
      formCampaign: {
        id: null,
        name: '',
        sendDate: '',
      },
      formDialogCampaignStatus: false,
      removeDialogCampaignStatus: false,
    }
  },

  computed: {
    ...mapState('reportServices', [
      'rows',
    ]),
  },

  async mounted() {
    setInterval(async () => {
      await this.fetchReportServices()
    }, 60000)
  },

  async created() {
    this.services = await this.fetchServices()

    await this.fetchReportServices()
  },

  methods: {
    ...mapActions('reportServices', [
      'find',
      'getSmsPhones',
      'getWhatsAppPhones',
      'getVoicePhones',
      'cancelCampaign',
      'updateCampaign',
    ]),
    ...mapActions('services', {
      fetchServices: 'find',
    }),
    ...mapActions('serviceSms', {
      getServiceSms: 'findById',
    }),
    ...mapActions('serviceSanitizer', {
      getServiceSanitizer: 'findById',
    }),
    ...mapActions('serviceVoice', {
      getServiceVoice: 'findById',
    }),
    openEditModal(rowId) {
      const campaign = this.rows.find(campaign => campaign.id === rowId)
      if (campaign) {
        // Atualizando formCampaign com os dados da campanha selecionada
        this.formCampaign = {
          id: campaign.id,
          name: campaign.name,
          sendDate: new Date(campaign.sendDate),
        }
        this.formDialogCampaignStatus = true
      } else {
        console.error(`No campaign found with id: ${rowId}`)
      }
    },
    fetchActionCampaign() {
      this.updateCampaign(this.formCampaign)
      this.find()
      this.formDialogCampaignStatus = false
    },
    async openDialog(row) {
      const {
        id, serviceId, serviceName, userName,
      } = row
      let data

      if ([serviceType.SMSShortcode, serviceType.SMSToken, serviceType.SMSFlash, serviceType.SMSMassive, serviceType.SMSBet].includes(serviceId)) {
        data = await this.getServiceSms(id)
        this.campaignServiceSelected = data.sms
      }

      if (serviceId === serviceType.Voice30s) {
        data = await this.getServiceVoice(id)
        this.campaignServiceSelected = data.voice
      }

      if (serviceId === serviceType.Voice60s) {
        data = await this.getServiceVoice(id)
        this.campaignServiceSelected = data.voice
      }

      if (serviceId === serviceType.Sanitizer) {
        data = await this.getServiceSanitizer(id)
      }

      this.formCampaign = {
        id: data.campaign.id,
        name: data.campaign.name,
      }

      this.campaignDialog = true
      this.campaignSelected = {
        ...data.campaign,
        serviceName,
        userName,
      }
    },

    async fetchReportServices() {
      await this.find({
        campaignId: this.campaignId,
        campaignName: this.campaignName,
        internalStatus: this.internalStatus,
        isPaused: this.isPaused,
        sendDateStart: this.sendDateStart ? this.$day(this.sendDateStart, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        sendDateEnd: this.sendDateEnd ? this.$day(this.sendDateEnd, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        serviceId: this.serviceId,
      })
    },

    async downloadReport() {
      if ([serviceType.SMSShortcode, serviceType.SMSToken, serviceType.SMSFlash, serviceType.SMSMassive, serviceType.SMSBet].includes(this.campaignSelected.serviceId)) {
        await this.getSmsPhones(this.campaignSelected.id)
      }

      if (this.campaignSelected.serviceId === serviceType.Voice30s) {
        await this.getVoicePhones(this.campaignSelected.id)
      }

      if (this.campaignSelected.serviceId === serviceType.Voice60s) {
        await this.getVoicePhones(this.campaignSelected.id)
      }

      if (this.campaignSelected.serviceId === serviceType.Sanitizer) {
        await this.getWhatsAppPhones(this.campaignSelected.id)
      }
    },

    async cancelSelectedCampaign() {
      await this.cancelCampaign(this.campaignSelected.id)
      await this.fetchReportServices()

      this.campaignDialog = false
    },
  },
}

</script>
